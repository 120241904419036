









import { Component, Vue } from 'vue-property-decorator'
import StudentCurrentObjective from '@/components/organisms/v3/StudentCurrentObjective.vue'
import StudentPreviousObjectives from '@/components/organisms/v3/StudentPreviousObjectives.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import StudentGoalHeader from '@/components/organisms/v3/StudentGoalHeader.vue'

@Component({
  components: {
    StudentCurrentObjective,
    StudentPreviousObjectives,
    ButtonBase,
    StudentGoalHeader
  },
})
export default class GoalList extends Vue {
}
