









import { Component, Vue } from 'vue-property-decorator'
@Component
export default class StudentGoalHeader extends Vue {
  private currentPath = this.$route.path
  private idGoalList = 1
  private linkList = [
    {
      id: 1,
      text: '現在の目標',
      link: '/student/v3/goal-list',
    },
    {
      id: 2,
      text: '目標達成状況',
      link: '/student/v3/history/target-situation',
    },
  ]
}
