



























import { Component, Vue, Watch, Ref, Mixins } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import { FormatResultSummaries } from '@/models/api/resultSummaries'
import TopGoal from '@/components/organisms/v3/dashboard/TopGoal.vue'
import TopSubjectSituation from '@/components/organisms/v3/dashboard/TopSubjectSituation.vue'
import TopSchedule from '@/components/organisms/v3/dashboard/TopSchedule.vue'
import { CurriculumsResult } from '@/models/api/v3/history/curriculums'
import TopDefault from '@/components/organisms/v3/dashboard/TopDefault.vue'
import { DASHBOARD, LIST_TEXT_RANDOM, SETTING_KEY } from '@/constants'
import NotificationList from '@/components/organisms/v3/NotificationList.vue'
import moment from 'moment'
import ModalNotification from '@/components/organisms/v3/dashboard/ModalNotification.vue'
import PropertiesDesign from '@/mixins/v3/PropertiesDesign'
import PopupBase from '@/components/molecules/v3/PopupBase.vue'
import SelectSubject from '@/components/atoms/SelectSubject.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ModalGpt from '@/components/organisms/v3/ModalGpt.vue'

@Component({
  components: {
    SidebarSwitcher,
    TopDefault,
    TopGoal,
    TopSubjectSituation,
    TopSchedule,
    NotificationList,
    ModalNotification,
    PopupBase,
    SelectSubject,
    ButtonBase,
    ModalGpt,
  },
})
export default class Dashboard extends Mixins(PropertiesDesign) {
  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId
  private pickSetting = DASHBOARD.default
  // cookieセット完了フラグ

  private intervalId: any = 0
  private get randomThinkingText() {
    const textRandom = LIST_TEXT_RANDOM[Math.floor(Math.random() * LIST_TEXT_RANDOM.length)]
    if (!textRandom.startsWith('name')) return textRandom
    return textRandom.replace('name', Vue.prototype.$cookies.get('dataGdls').userNickname)
  }

  private notificationDatas: any = []

  private understandingTotal = {}
  private understandingHistories: object[] = []

  private isSubjectChooseStep = true
  private showRobotIcon = Vue.prototype.$cookies.get('dataGdls').showEncouragementText

  @Watch('listMode', { deep: true })
  async watchListMode() {
    await this.loadLatestCurriculum()
  }
  @Ref()
  modalGptRef!: ModalGpt

  @Ref()
  modalNotification!: ModalNotification

  private get listMode() {
    return this.$store.getters['listModeV3/listMode']
  }

  private changeToGptStep() {
    this.isSubjectChooseStep = false
  }
  private handleShowSubjectPopup() {
    if (this.isZoomOut) {
      this.isZoomOut = false
    } else {
      this.modalGptRef.show()
      this.isSubjectChooseStep = true
    }
  }

  private getSelectSetting() {
    if (!this.onlyShow?.dashboard) {
      let select: string = DASHBOARD.default + ''
      if (localStorage.getItem(SETTING_KEY)) {
        select = localStorage.getItem(SETTING_KEY) || select
      }
      return select
    }
    return DASHBOARD[this.onlyShow.dashboard]
  }

  private showNotification(id: number): void {
    this.modalNotification.loadNotification(id)
  }

  private async loadNotifications() {
    const params = ['role=student']
    await Vue.prototype.$http.httpWithToken.get(`/notifications?${params.join('&')}`).then((res: any) => {
      this.notificationDatas = res.data.notifications.map(
        (notification: { id: number; title: string; startedAt: Date }) => {
          return {
            date: moment(notification.startedAt).format('M/DD(金)HH:mm'),
            title: notification.title,
            click: this.showNotification,
            variable: notification.id,
          }
        }
      )
    })
  }

  // 学習状況取得（補助教材以外全てのモード）
  private async loadResultSummaries() {
    await Vue.prototype.$http.httpWithToken.get(`/v3/history/resultSummaries/${this.userId}`).then((res: any) => {
      this.understandingTotal = FormatResultSummaries(res.data)
    })
  }

  // 直近の履歴を取得（通常、講習会、AIモードのみ）
  private async loadLatestCurriculum() {
    if (this.listMode.length === 0) return
    const modeCode = this.listMode.map((item: any) => item.code).join(',')
    await Vue.prototype.$http.httpWithToken
      .get('/v3/history/resultDrills/latestCurriculum', {
        params: {
          userId: this.userId,
          classModeCode: modeCode,
          classCategoryCode: 'RK,EN',
        },
      })
      .then((res: any) => {
        this.understandingHistories = res.data.map((curriculums: CurriculumsResult) => {
          const rateText = `${curriculums.predictedScoreMin}%-${curriculums.predictedScoreMax}%`
          const count = Object.values(curriculums.count).reduce((sum: number, cnt: number) => sum + cnt, 0)
          return {
            sUnitId: curriculums.sUnitId,
            subject: { name: curriculums.subjectName, code: curriculums.subjectCode },
            content: curriculums.sUnitName,
            date: curriculums.date,
            count: count,
            rates: [{ rate: curriculums.predictedScore, rateText: rateText }],
          }
        })
      })
  }

  private async mounted(): Promise<void> {
    this.pickSetting = this.getSelectSetting()
    Vue.prototype.$loading.start()
    if (this.pickSetting == 1) {
      await this.loadNotifications()
      await this.loadResultSummaries()
      await this.loadLatestCurriculum()
    }
    // 授業の開始情報は5分おきに取得する
    this.intervalId = setInterval(() => {
      // cookieの期限切れであれば生徒ログイン画面に遷移する
      if (!Vue.prototype.$cookies.get('dataGdls')) {
        const isSozo = Vue.prototype.$gdlsCookiesV3?.isSozo()
        if (isSozo) {
          this.$router.push({ name: 'StudentSozoLogin' })
        } else {
          this.$router.push({ name: 'StudentsV3Login' })
        }
        return
      }
    }, 300000)
    Vue.prototype.$loading.complete()
  }

  private beforeDestroy() {
    clearInterval(this.intervalId)
  }

  private isZoomOut = false
}
