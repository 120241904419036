










import { Component, Mixins, Vue, Prop, Watch } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/v3/ModalBase2.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import SelectSubject from '@/components/atoms/SelectSubject.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ModalBase,
    SelectSubject,
    ButtonBase,
  },
})
export default class ModalGpt extends Mixins(ModalBaseMethod) {
  @Prop() private title!: string
  @Prop() private isSubjectChooseStep!: boolean
  @Prop() private changeToGptStep!: () => void

  @Watch('isSubjectChooseStep')
  private async getTextFromGpt() {
    if (this.isSubjectChooseStep || !this.selectedSubject) {
      this.textFromGpt = ''
      return
    }
    const idTimeout = setTimeout(() => {
      this.loadingGpt = true
    }, 4000)
    const subject = this.subjectList.find(
      (subjectItem: { id: number; name: string; code: string; sort_num: number }) =>
        subjectItem.code === this.selectedSubject
    )
    const response = await Vue.prototype.$http.httpWithToken(`v3/gpt/encouragementText?subjectId=${subject.id}`)
    this.textFromGpt = response.data.message
    clearTimeout(idTimeout)
    this.loadingGpt = false
  }

  private loadingGpt = false

  private subjectList: any = []

  private async mounted() {
    const response = await Vue.prototype.$http.httpWithToken('subjects')
    this.subjectList = response.data
    this.selectedSubject = response.data[0].code
  }
  private selectedSubject = ''

  private textFromGpt = ''
}
