
































import { Component, Mixins } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import { StudentGoal } from '@/models/api/studentGoal'

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalObjectiveDetail extends Mixins(ModalBaseMethod) {
  private goal: StudentGoal | null = null

  public showCurrentGoal(goal: StudentGoal) {
    this.goal = goal
    this.show()
  }
}
