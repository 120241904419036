



















import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'
import { StudentGoal } from '@/models/api/studentGoal'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'

@Component({
  components: {
    ModalBase,
    ButtonBase,
  },
})
export default class ModalSaveTestResult extends Mixins(ModalBaseMethod, ClassModeMethods) {
  private goal: StudentGoal | null = null

  private formDatas: { [key: string]: number | null } = {}

  public showResult(goal: StudentGoal) {
    this.goal = goal
    this.initFormDatas()
    this.show()
  }

  private initFormDatas() {
    const subjects = this.goal?.studentGoalTermExams || []
    this.formDatas = {}
    const baseForm = {}
    subjects.forEach((i) => {
      baseForm[i.subjectCode] = i.resultScore || null
    })
    this.formDatas = baseForm
  }

  private get isSaveAble() {
    return Object.values(this.formDatas).filter((i) => !(i != null && i >= 0 && i <= 100)).length == 0
  }

  private get saveBtnColor() {
    return this.isSaveAble ? 'skyblue' : 'pointer-events-none'
  }

  private onInputResult(subjectCode: string, value: string) {
    if (parseInt(value) > 100) value = '100'
    this.formDatas[subjectCode] = null
    this.formDatas[subjectCode] = parseInt(value) || null
  }

  private async onClickSave() {
    if (!this.isSaveAble) return

    Vue.prototype.$loading.start()
    const params = { resultScores: this.formDatas }
    await Vue.prototype.$http.httpWithToken
      .patch(`/studentGoals/${this.goal?.id}/setResultTermExam`, params)
      .catch((error: any) => {
        if (error?.response?.data?.status === 404) alert('対象の目標が存在しません')
      })
      .finally(() => {
        Vue.prototype.$loading.complete()
        this.$emit('saved')
        this.hide()
      })
  }
}
