
















import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import { StudentGoal } from '@/models/api/studentGoal'

@Component({
  components: {
    ButtonBase,
  },
})
export default class ObjectiveProgress extends Mixins(ClassModeMethods) {
  @Prop({ default: 'TJ' })
  classMode!: string

  private get modeName() {
    return `${this.classModeText(this.classMode)}モード`
  }

  private onClickDetail(): void {
    this.$emit('on-click-detail-btn', this.goal)
  }

  private onClickResult(): void {
    this.$emit('on-click-result-btn', this.goal)
  }

  @Prop()
  goal!: StudentGoal

  @Prop({ default: false })
  showResultBtn?: boolean
}
