





import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TopGoal extends Vue {
  private get linkSchedule() {
    const domain = process.env.VUE_APP_MANAMIRU_URL
    const branchId = Vue.prototype.$cookies.get('dataGdls').branchId
    const accessToken = Vue.prototype.$cookies.get('authGdls').accessToken
    const client = Vue.prototype.$cookies.get('authGdls').client
    const uid = Vue.prototype.$cookies.get('authGdls').uid
    return `${domain}/student?branchId=${branchId}&access-token=${accessToken}&client=${client}&uid=${uid}`
  }
}
