import { render, staticRenderFns } from "./ModalBase2.vue?vue&type=template&id=33ad9353&scoped=true&lang=pug&"
import script from "./ModalBase2.vue?vue&type=script&lang=ts&"
export * from "./ModalBase2.vue?vue&type=script&lang=ts&"
import style0 from "./ModalBase2.vue?vue&type=style&index=0&id=33ad9353&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33ad9353",
  null
  
)

export default component.exports