
















import { Component, Vue, Ref, Mixins } from 'vue-property-decorator'
import ObjectiveProgress from '@/components/molecules/ObjectiveProgress.vue'
import ModalObjectiveDetail from '@/components/organisms/ModalObjectiveDetail.vue'
import ModalSaveTestResult from '@/components/organisms/ModalSaveTestResult.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import { StudentGoal } from '@/models/api/studentGoal'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'

@Component({
  components: {
    ObjectiveProgress,
    ModalObjectiveDetail,
    ModalSaveTestResult,
  },
})
export default class StudentPreviousObjectives extends Mixins(ClassModeMethods, ClassModeChangeAble) {
  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private goals: StudentGoal[] = []

  private get showResultBtn() {
    return this.classMode() == this.CLASS_MODE.TEST
  }

  @Ref() modalObjectiveDetail!: ModalObjectiveDetail

  @Ref() modalSaveTestResult!: ModalSaveTestResult

  private showDetail(goal: StudentGoal) {
    this.modalObjectiveDetail.showCurrentGoal(goal)
  }

  private showRegisterResult(goal: StudentGoal) {
    this.modalSaveTestResult.showResult(goal)
  }

  private async mounted(): Promise<void> {
    await this.checkClassModeAndSideMenuMode()
    this.loadPrevGoals()
  }

  /**
   * これまでの目標一覧取得
   */
  private async loadPrevGoals() {
    const { data }: { data: StudentGoal[] } = await Vue.prototype.$http.httpWithToken.get('/v3/student_goals', {
      params: { isWithoutCurrent: true },
    })
    // 現在の目標取得
    this.goals = data
  }
}
